.OpenResult {
  background: white;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .logoContainer {
    width: 50%;
    display: flex;
    justify-content: center;
    .appLogo {
      width: 240px;
    }
    margin-bottom: 28px;
  }
  .MarqueeContainer {
    width: 500px;
    .marquee {
      animation: slide 10s linear infinite;
    }
    margin-bottom: 28px;
    @keyframes slide {
      0% {
        transform: translatex(0%);
      }

      100% {
        transform: translatex(100%);
      }
    }
  }
  .FormContainer {
    width: 50%;
    box-sizing: border-box;

    height: 140px;
    background-color: white;
    border-radius: 12px;
    padding: 12px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    .WidgetList {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .WidgetContainer {
        width: 30%;
      }
    }
    .InputContainers {
      margin-top: 18px;
      display: flex;
      align-content: center;
      .InputContainer {
        width: 44%;
        margin-right: 12px;
      }
    }
  }
  .ResultContainer {
    margin-top: 24px;
    width: 50%;
    background-color: white;
    border-radius: 12px;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    .UserInfoContainer {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .InfoGroup {
        margin-right: 24px;
        margin-bottom: 24px;
        width: 30%;
      }
    }
    .ResultList {
      .ResultItem {
        background-color: #d9d9d9;
      }
    }
  }
}
